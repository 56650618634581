<template>
  <div class="promotion-modal">
    <!--h3 class="promotion-modal__header">{{ $t("freeAdd.extensionProductTitle") }}</h3-->
    <main class="promotion-modal__content">
      <img :src="promotion.logo" class="promotion-modal__logo" alt="" />
      <section class="promotion-modal__items">
        <no-ssr>
          <VueSlickCarousel v-bind="settingsCarousel" ref="carousel" @beforeChange="onSlideChange" @init="initCarousel">
            <div
              class="promotion-modal-carousel__item"
              v-for="slide in slides"
              :key="slide.id"
              :data-product-id="slide.id"
            >
              <div class="promotion-modal-carousel__card">
                <div class="promotion-modal-carousel__cover">
                  <img
                    :src="slide.imageUrl"
                    @click="goToProduct(slide.id, slide['slug'])"
                    alt=""
                  />
                </div>
                <div class="promotion-modal-carousel__content">
                  <div class="promotion-modal-carousel__head">
                    <h3 class="promotion-modal-carousel__title">
                      {{ slide.title }}
                    </h3>
                    <div class="promotion-modal-carousel__price" v-if="!slide.salePrice">
                      <bdi>
                        {{ $utils.formatMoney(slide.price) }}
                      </bdi>
                    </div>

                    <div
                      class="promotion-modal-carousel__price"
                      :class="slide.salePrice ? 'sotros__price--sale' : ''"
                      v-else
                    >
                      <del>
                        <bdi>{{ slide.price }} <span class="currecy-symbol">€</span></bdi>
                      </del>
                      <bdi
                        >{{ slide.salePrice }} <span class="currecy-symbol">€</span>
                      </bdi>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </VueSlickCarousel>
        </no-ssr>
      </section>

      <aside class="promotion-modal__actions">
        <div class="slick-arrows">
          <div class="slick-arrow--back" @click="previousSlide()">
            <img src="../../../assets/img/ico/ico-arrow-slick-left.svg" alt="" />
          </div>

          <div class="slick-arrow--next" @click="nextSlide()">
            <img
              src="../../../assets/img/ico/ico-arrow-slick-left.svg"
              alt=""
              style="transform: rotate(180deg)"
            />
          </div>
        </div>

        <LoadingButton v-if="isAdding" />
        <button
          v-else
          class="button button--dark button--block button--ico"
          @click="addProduct(currentProductId)"
          :disabled="isInTheList(currentProductId)"
        >
          <i :class="isInTheList(currentProductId) ? 'uil' : 'uil uil-plus'"></i>
          {{
            isInTheList(currentProductId)
              ? $t("mylist.added")
              : $t("mylist.addToYourList")
          }}
        </button>
      </aside>
    </main>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

import VueSlickCarousel from "vue-slick-carousel";
import "vue-slick-carousel/dist/vue-slick-carousel.css";
import "vue-slick-carousel/dist/vue-slick-carousel-theme.css";
import NoSSR from "vue-no-ssr";

import LoadingButton from "../../../components/LoadingButton.vue";

export default {
  name: "Promotion",
  components: {
    LoadingButton,
    VueSlickCarousel,
    "no-ssr": NoSSR,
  },
  props: {
    promotion: {
      type: Object,
      required: true,
    },
  },
  computed: {
    ...mapGetters({
      myList: "ownerList",
      ownerListType: "ownerListType",
    }),
    slides() {
      return this.promotion.products;
    },
  },
  data() {
    return {
      isAdding: false,
      currentProductId: null,
      settingsCarousel: {
        draggable: true,
        slidesToShow: 1,
        responsive: [
          {
            breakpoint: 100000000,
            settings: {
              arrows: true,
            },
          },
          {
            breakpoint: 767,
            settings: {
              arrows: true,
              draggable: true,
            },
          },
          {
            breakpoint: 575,
            settings: {
              autoplay: false,
              arrows: false,
              dots: true,
            },
          },
        ],
      },
    };
  },
  methods: {
    isInTheList(id, update = true) {
      return this.myList.products.some(
        (product) => Number.parseInt(product.id, 10) === Number.parseInt(id, 10)
      );
    },
    goToProduct(id, slug) {
      //open new tab
      window.open("https://www.hellobb.net/catalogo/" + slug + "/" + id, "_blank");
    },
    async addProduct(id) {
      const slide = this.slides.find(
        (slide) => Number.parseInt(slide.id, 10) === Number.parseInt(id, 10)
      );
      console.log({ id, slide, slides: this.slides });
      this.isAdding = true;
      try {
        let body = {};
        let product = {
          id: slide.id,
        };
        await this.$store.dispatch("addProductToList", {
          list: this.myList,
          product: product,
          body,
        });
        this.trackAddProduct(slide);
        await this.getList();
      } catch (error) {
        console.log(error);
      } finally {
        this.isAdding = false;
      }
    },
    async getList() {
      try {
        await this.$store.dispatch("getOwnerList");
        await this.$store.dispatch("getPromotions", this.ownerListType);
      } catch (error) {
        console.log(error);
      }
    },
    trackAddProduct(slide) {
      console.log({
        "Item Category": "TBD",
        "Item name": slide.name,
        //"Item Price": this.product.price,
        "Add type": "Objecte catàleg",
        "Add Platform": this.$getPlatform(),
        "Add client": this.$store.state.cookies.sourceApp == "true" ? "App" : "Webapp",
        Source: "Banner producte promocionat",
        "Num retailers": 1,
        "Item retailer": "HelloBB",
        "Item retailers": ["HelloBB"],
        "Sold by HelloBB": true,
        "Item URL": "https://www.hellobb.net/p/" + slide.id,
        "Product ID": slide.id,
      });
      return;

      window.mixpanel.track("Afegir objecte", {
        "Item Category": "TBD",
        "Item name": slide.name,
        //"Item Price": this.product.price,
        "Add type": "Objecte catàleg",
        "Add Platform": this.$getPlatform(),
        "Add client": this.$store.state.cookies.sourceApp == "true" ? "App" : "Webapp",
        Source: "Banner producte promocionat",
        "Num retailers": 1,
        "Item retailer": "HelloBB",
        "Item retailers": ["HelloBB"],
        "Sold by HelloBB": true,
        "Item URL": "https://www.hellobb.net/p/" + slide.id,
        "Product ID": slide.id,
      });
      window.mixpanel.people.increment("Total Add");
      window.mixpanel.people.increment("List # elements");
      window.mixpanel.people.increment("List value", slide.price);
      window.mixpanel.people.set({ "Last Add": new Date().toISOString() });
      window.mixpanel.people.set_once({ "First Add": new Date().toISOString() });
    },
    nextSlide() {
      return this.$refs.carousel.next();
    },
    previousSlide() {
      return this.$refs.carousel.prev();
    },
    onSlideChange(oldIndex, newIndex) {
      this.currentProductId = this.slides[newIndex].id;

      console.log({
        currentProductId: this.currentProductId,
      });
    },
    async initCarousel(...args) {
      while (!this.$refs.carousel) {
        await this.$sleep(() => {}, 100);
      }
      console.log("Initializing carousel.");

      // When moving between desktop and mobile view breakpoints, the displayed
      // slide might be different, but no change events are triggered.
      //
      // For example, if we're in mobile view and we see the last slide, and
      // then we move to desktop view, the state will still be the last slide,
      // but the carousel might show the first slide.
      //
      // To avoid this inconsistency, when changing views, we force the
      // carousel to show the first slide and set the corresponding state.

      const i = 0;

      this.currentProductId = this.slides[i].id;
      return this.$refs.carousel.goTo(i);
    },
  },
  async mounted() {
  },
  beforeDestroy() {
    if (this.$refs.carousel && typeof this.$refs.carousel.destroy === "function") {
      this.$refs.carousel.destroy();
      console.log("Carrusel destruido");
    }
  },
  watch: {
    slides(newSlides, oldSlides) {
      if (newSlides.length !== oldSlides.length) {
        this.$nextTick(() => {
          if (this.$refs.carousel) {
            this.$refs.carousel.refresh();
          }
        });
      }
    },
  },
};
</script>
